<template>
  <div class="col-12 col-sm-12 col-md-4 wrapper d-flex flex-column">
    <div class="d-flex">
      <h1 class="title mt-0 fs-b fs-2500 color-pr">
        <span class="ri-arrow-left-line" @click="toLogin()" />
        Réinitialiser le mot de passe
      </h1>
    </div>

    <div class="container">
      <div class="d-flex">
        <div class="form-mdp mr-auto p-2">
          <label class="text-label" for="mdp1"
            >Entrer un nouveau mot de passe *</label
          >
          <input
            class="fr-input"
            id="mdp1"
            :type="passwordFielType1"
            v-model="mdp1"
            @input="validateMdp(mdp1, mdp2)"
          />
          <span
            v-show="showMdp1"
            class="ri-eye-off-line input-eye"
            @click="showPassword1()"
            title="Cacher le mot de passe"
          />
          <span
            v-show="!showMdp1"
            class="ri-eye-line input-eye"
            @click="showPassword1()"
            title="Afficher le mot de passe"
          ></span>
        </div>

        <div class="form-mdp p-2">
          <label class="text-label" for="mdp2"
            >Confirmation du mot de passe *</label
          >
          <input
            class="fr-input"
            id="mdp2"
            :type="passwordFielType2"
            v-model="mdp2"
            @input="validateMdp(mdp1, mdp2)"
          />
          <span
            v-show="showMdp2"
            class="ri-eye-off-line input-eye"
            @click="showPassword2()"
            title="Cacher le mot de passe"
          />
          <span
            v-show="!showMdp2"
            class="ri-eye-line input-eye"
            @click="showPassword2()"
            title="Afficher le mot de passe"
          ></span>
        </div>
      </div>

      <div class="form-controleMdp">
        <p class="text-info">
          Le mot de passe doit répondre aux exigences suivantes :
        </p>
        <p class="text-label">
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.tailleMdp,
              'ri-close-line': !controleMdp.tailleMdp
            }"
          />
          être compris entre 12 et 20 caractères
        </p>
        <p class="text-label">
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.minuscule,
              'ri-close-line': !controleMdp.minuscule
            }"
          />
          contenir 1 minuscule hors caractères accentués
        </p>
        <p class="text-label">
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.majuscule,
              'ri-close-line': !controleMdp.majuscule
            }"
          />
          contenir 1 majuscule hors caractères accentués
        </p>
        <p class="text-label">
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.chiffre,
              'ri-close-line': !controleMdp.chiffre
            }"
          />
          contenir 1 chiffre
        </p>
        <p
          class="text-label"
          alt="Parenthèse ouvrante, tilde, point d'exclamation, arobase, dièse, dollar, pourcentage, accent circonflexe, et commercial, astérique, tiret du 8, moins, plus, égal, trait vertical, accolade ouvrante, accolade fermante, crochet ouvrant, crochet fermant, deux-points, point-virgule, point, point d'interrogation, parenthèse fermante"
        >
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.special,
              'ri-close-line': !controleMdp.special
            }"
          />
          contenir 1 caractère spécial parmi la liste
          <span class="sr-only"
            >Tilde, point d'exclamation, arobase, dièse, dollar, pourcentage,
            accent circonflexe, et commercial, astérisque, tiret du 8, moins,
            plus, égal, trait vertical, parenthèse ouvrante, parenthèse
            fermante, accolade ouvrante, accolade fermante, crochet ouvrant,
            crochet fermant, deux point, point virgule, virgule, point, point
            d'intérogation</span
          >
          <span aria-hidden="true"> (~!@#$%^&*_-+=|() {} [ ]:;,.?)</span>
        </p>
        <p class="text-label">
          <span
            v-bind:class="{
              'ri-check-line': controleMdp.memeMdp,
              'ri-close-line': !controleMdp.memeMdp
            }"
          />
          être confirmé une seconde fois
        </p>
      </div>

      <div
        v-if="msgErreur && msgErreur.length !== 0"
        class="fr-alert fr-alert--error ri-alert-line"
      >
        <p class="fr-alert__title">{{ msgErreur }}</p>
      </div>

      <div
        v-if="msgSuccess && msgSuccess.length !== 0"
        class="fr-alert fr-alert--success"
      >
        <p class="fr-alert__title">{{ msgSuccess }}</p>
      </div>

      <div class="form-button">
        <button
          class="col btn"
          :disabled="!allowValidation"
          @click="valider(mdp1, mdp2)"
        >
          Valider
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import store from '../store';

export default {
  data() {
    return {
      fullname: store.state.user.fullname,
      email: store.state.user.email,
      mdp1: '',
      mdp2: '',
      showMdp1: false,
      showMdp2: false,
      passwordFielType1: 'password',
      passwordFielType2: 'password',
      msgErreur: '',
      msgSuccess: '',
      controleMdp: {
        minuscule: false,
        majuscule: false,
        chiffre: false,
        special: false,
        memeMdp: false,
        tailleMdp: false
      },
      allowValidation: false
    };
  },
  mounted() {
    this.checkValidationCode();
  },
  methods: {
    async checkValidationCode() {
      this.$compteUtilisateurService
        .validationCodeReinitialiserMotDePasse(this.$route.query.confirmation)
        .then((res) => {
          if (res.status === 200 && res.data.resultat == 202) {
            return;
          } else if (res.data.infos && res.data.infos[0]) {
            this.$router.push({
              name: 'Réinitialisation du mot de passe'
            });
          } else {
            this.$router.push({
              name: 'retry',
              query: {
                statusMsg:
                  "Suite à une erreur technique, nous n'avons pas pu réinitialiser votre mot de passe"
              }
            });
          }
        })
        .catch((err) => {
          console.error('erreur', err);
          this.$router.push({
            name: 'retry',
            query: {
              statusMsg:
                "Suite à une erreur technique, nous n'avons pas pu réinitialiser votre mot de passe"
            }
          });
        });
    },
    showPassword1() {
      this.showMdp1 = !this.showMdp1;
      this.passwordFielType1 =
        this.passwordFielType1 === 'password' ? 'text' : 'password';
    },
    showPassword2() {
      this.showMdp2 = !this.showMdp2;
      this.passwordFielType2 =
        this.passwordFielType2 === 'password' ? 'text' : 'password';
    },
    isControleMdpOK() {
      return (
        this.controleMdp.minuscule &&
        this.controleMdp.majuscule &&
        this.controleMdp.chiffre &&
        this.controleMdp.special &&
        this.controleMdp.tailleMdp &&
        this.controleMdp.memeMdp
      );
    },
    validateMdp(mdp1, mdp2) {
      this.msgErreur = '';

      if (mdp1 && mdp2 && mdp1 === mdp2) {
        this.controleMdp.memeMdp = true;
      } else if (mdp1 !== mdp2) {
        this.controleMdp.memeMdp = false;
      }
      this.controleMdp.minuscule = /[a-z]/.test(mdp1);
      this.controleMdp.majuscule = /[A-Z]/.test(mdp1);
      this.controleMdp.chiffre = /[0-9]/.test(mdp1);
      // eslint-disable-next-line no-useless-escape
      this.controleMdp.special = /[\!\@\#\$\%\^\&\*\)\(\+\=\.\{\}\[\]\:\;\|\~\_\-\,\?]/.test(
        mdp1
      );
      this.controleMdp.tailleMdp =
        mdp1 && 12 <= mdp1.length && mdp1.length <= 20;
      // eslint-disable-next-line no-useless-escape
      const allConditions = /^[a-zA-Z0-9\!\@\#\$\%\^\&\*\)\(\+\=\.\{\}\[\]\:\;\|\~\_\-\,\?]+$/.test(
        mdp1
      );

      if (this.isControleMdpOK() && allConditions) {
        this.allowValidation = true;
        return true;
      } else if (this.isControleMdpOK() && !allConditions) {
        this.msgErreur =
          'Un caractère non autorisé est utilisé. Veuillez vérifier votre saisie en respectant la liste ci-dessus.';
      }
      this.allowValidation = false;
      return false;
    },
    valider(mdp1, mdp2) {
      if (this.validateMdp(mdp1, mdp2)) {
        let html = '';
        const confirmation = this.$route.query.confirmation;
        const compte = {
          key: confirmation,
          password: mdp1,
          properties: []
        };
        this.$compteUtilisateurService
          .resetPassword(compte)
          .then((res) => {
            if (res.status === 200 && res.data.resultat === 200) {
              html = `<p class="fs-875">La rénitialisation du mot de passe a bien été enregistré</p>`;
              this.setContent({ html, action: 'info' });
              this.showPopup();
              this.emitter.once('popup', (payload) => {
                if (payload === 'ok' || payload === 'hide') {
                  this.$router.push({ name: 'Mon compte' }).catch(() => {});
                }
              });
            } else if (
              res.status === 200 &&
              res.data &&
              !res.data.resultat &&
              res.data.infos &&
              res.data.infos.length !== 0 &&
              res.data.infos[0].infoCode === '30010'
            ) {
              html =
                `<p class="fs-875">` + res.data.infos[0].infoMessage + `</p>`;
              this.setContent({ html, action: 'info' });
              this.showPopup();
            } else {
              html = `<p class="fs-875">Une erreur est survenue.</p>`;
              this.setContent({ html, action: 'info' });
              this.showPopup();
              this.emitter.once('popup', (payload) => {
                if (payload === 'ok' || payload === 'hide') {
                  this.$router.push({ name: 'Mon compte' }).catch(() => {});
                }
              });
            }
          })
          .catch((err) => {
            console.error('erreur', err);
          });
      } else {
        // afficher erreur
      }
    },
    toLogin() {
      this.$router.push({ name: 'Mon compte' });
    },
    ...mapActions(['showPopup', 'setContent'])
  }
};
</script>

<style lang="scss" scoped>
h1 {
  color: $base-color;
  display: flex;
}

.ri-arrow-left-line {
  width: 18rem;
  color: black;
  cursor: pointer;
}

.bloc {
  width: auto;
  display: flex;
  padding: 1rem;
}

.container {
  width: 60%;
  padding: 3%;
  padding-right: 2%;
  border: 2px solid #dddddd;
  margin: 2%;
  margin-left: 16%;
}

.fr-fi-user-line::before {
  font-size: 2.5rem;
  border-radius: 50%;
  box-shadow: 0 0 2px #888;
  padding: 0.3em 0.3em;
}

.fr-fi-user-line {
  font-size: 2.5rem;
  padding: 2%;
}

.title-bloc {
  font-size: 20px;
}

.text-label {
  font-size: 14px;
}

.text-info {
  color: #6a6a6a !important;
  font-size: 12px;
  font-style: italic;
}
.form-controleMdp {
  display: grid;
  padding-top: 0.5rem;
  width: 70%;
}
.form-mdp {
  display: grid;
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 70%;
}

.fr-input:focus {
  z-index: 0;
  box-shadow: 0 0 0 0 transparent inset;
}

.input-number {
  display: flex;
}

.indicator {
  width: min-content;
}

.btn {
  background: $base-color;
  color: white;
  font-size: 14px;
  border: 0;
  width: 100%;
  margin-left: 1rem;
}

.btn:hover {
  background-color: $base-color;
}

.input-eye {
  width: 19px;
  height: 16px;
  bottom: 30px;
  right: -90%;
  position: initial;
  margin-left: 90%;
  margin-top: -30px;
  color: $base-color;
}

@supports (background: -webkit-named-image(i)) {
  .fr-input:focus + .input-eye {
    margin-left: 82%;
    right: -82%;
  }
}

.form-button {
  display: flex;
  width: 30%;
  margin-left: 30%;
  height: 46px;
  padding-top: 0.375rem;
}

.ri-close-line {
  color: #9f3a38;
}

.ri-check-line {
  color: green;
}

.form-error {
  white-space: normal;
  background: #ffffff !important;
  color: #9f3a38 !important;
  padding-bottom: 1rem;
}

.fr-alert--error {
  width: 80%;
}

.fr-alert--success {
  width: 70%;
}
</style>
